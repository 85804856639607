import { css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (theme: Theme) => css`
  border-color: ${theme.color.black};
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  position: relative;
  display: block;
  padding: 10px 15px;
  height: 41px;
`;

export const active = (theme: Theme) => css`
  background: ${theme.color.black};
  color: ${theme.color.white};
`;

export const inactive = (theme: Theme) => css`
  background: ${theme.color.white};
  color: ${theme.color.black};
  &:hover {
    background: ${theme.color.grey30};
  }
`;
