import { useEffect, useMemo, useState } from 'react';
import { Serialize } from 'src/lib/serialize/types';
import usePrevious from 'src/hooks/usePrevious';

const getGlobal = (key: string): string | null =>
  (typeof window === 'undefined' ? undefined : window)?.localStorage?.getItem(key) ?? null;

const setGlobal =
  (x: string) =>
  (key: string): void =>
    (typeof window === 'undefined' ? undefined : window)?.localStorage?.setItem(key, x);

const usePersistingState = <A>(key: string, serialize: Serialize<A>) => {
  const [state, setState] = useState<A>(serialize.fromString(getGlobal(key) ?? ''));
  const serialized = useMemo(() => serialize.toString(state), [state, serialize.toString]);
  const prevKey = usePrevious(key);

  useEffect(() => {
    if (typeof prevKey === typeof key && prevKey !== key) {
      setState(serialize.fromString(getGlobal(key) ?? ''));
    }
  }, [key, prevKey]);
  useEffect(() => {
    setGlobal(serialized)(key);
  }, [key, serialized]);

  return [state, setState] as const;
};

export default usePersistingState;
