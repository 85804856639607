import { useCallback, useState } from 'react';
import * as rect from 'src/lib/rect';
import useIsomorphicLayoutEffect from 'src/hooks/useIsomorphicLayoutEffect';
import useWindow from 'src/hooks/useWindow';
import useWindowEvent from './useWindowEvent';

const useWindowRect = (): rect.types.Rect => {
  const [rx, setRect] = useState<rect.types.Rect>(rect.constants.empty);
  const w = useWindow();

  useIsomorphicLayoutEffect(() => {
    const mry = w && rect.fromWindow(w);
    if (mry && !rect.equal(mry, rx)) {
      setRect(mry);
    }
  }, [w]);

  useWindowEvent('resize', useCallback(() => {
    const mry = w && rect.fromWindow(w);
    if (mry && !rect.equal(mry, rx)) {
      setRect(mry);
    }
  }, [w]));

  return rx;
}

export default useWindowRect;
