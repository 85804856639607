import React, { useRef, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import useVirtualWindow from 'src/hooks/useVirtualWindow';
import * as array from 'src/lib/array';
import * as styles from './styles';
import ListGroup from 'src/components/ListGroup';

export type Props<A> = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  data: A[];
  children: (x: A, index: number) => React.ReactNode;
  overscan?: number;
};

function ListGroupData<A>({ data: xs, children, className, overscan = 5, ...rest }: Props<A>) {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualized = useVirtualWindow({
    parentRef,
    overscan,
    size: xs.length
  });

  return (
    <div className={cx(styles.main, className)} ref={parentRef} {...rest}>
      <ListGroup className={styles.list(virtualized.totalSize)}>
        {virtualized.virtualItems.map((item) => (
          <ListGroup.Item key={item.index} className={styles.item(item.start)} ref={item.measureRef}>
            {((x) => (x === null ? null : children(x, item.index)))(array.lookup(item.index, xs))}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default ListGroupData;
