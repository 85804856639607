import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
// https://github.com/vercel/next.js/issues/30720
const useRehydrationStatus = () => {
  const [state, setState] = useState<'pending' | 'complete'>('pending');
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      setState('complete');
    }
  }, [router]);

  return state;
};

export default useRehydrationStatus;
