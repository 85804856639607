import { css } from '@emotion/css';

export const main = css`
  overflow: auto;
`;

export const list = (size: number) => css`
  position: relative;
  height: ${size}px;
`;

export const item = (offset: number) => css`
  position: absolute; !important
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(${offset}px)
`;
