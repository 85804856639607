import React, { useCallback, useState, forwardRef, Ref, InputHTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import useTheme from 'src/hooks/useTheme';
import * as styles from './styles';

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> & {
  error?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  innerRef: Ref<HTMLInputElement>;
};

const InputText: React.FC<Props> = ({
  className,
  error,
  value,
  onChange = () => undefined,
  onBlur = () => undefined,
  onFocus = () => undefined,
  innerRef,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const theme = useTheme();

  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const onFocusInput = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus(event);
      setFocused(true);
    },
    [onFocus]
  );

  const onBlurInput = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur(event);
      setFocused(false);
    },
    [onBlur]
  );

  return (
    <input
      className={cx(styles.main(theme), focused ? styles.focus(theme) : styles.input(theme), className)}
      value={value}
      onChange={onChangeInput}
      onFocus={onFocusInput}
      onBlur={onBlurInput}
      ref={innerRef}
      {...rest}
    />
  );
};

export default forwardRef<HTMLInputElement, Omit<Props, 'innerRef'>>((props, ref) => {
  return <InputText {...props} innerRef={ref} />;
});
