import React from 'react';
import { cx } from '@emotion/css';
import { GetProps } from 'src/lib/react/types';
import useTheme from 'src/hooks/useTheme';
import Text from 'src/components/Text';
import * as styles from './styles';

export type Props = Omit<GetProps<typeof Text>, 'color'> & {
  color?: 'neutral' | 'success' | 'disabled';
};

const Label: React.FC<Props> = ({ color = 'success', className, ...rest }) => {
  const theme = useTheme();
  return (
    <Text
      className={cx(
        styles.main(theme),
        {
          neutral: styles.neutral(theme),
          success: styles.success(theme),
          disabled: styles.disabled(theme)
        }[color],
        className
      )}
      {...rest}
    />
  );
};

export default Label;
