import { useEffect, useRef } from 'react';

const usePrevious = <A>(value: A): A | undefined => {
  const ref = useRef<A | undefined>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePrevious;
