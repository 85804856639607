import { useCallback, useEffect, useState } from 'react';
import * as models from 'src/models';

type State = models.institutionFilterV2.types.InstitutionFilterV2;

type Payload = [
  State,
  {
    [K in keyof State]: (value: State[K]) => void;
  }
];

const useInstitutionFilterV2 = (initialState: State): Payload => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!models.institutionFilterV2.equal(initialState, state)) {
      setState(initialState);
    }
  }, [initialState.text, initialState.type, initialState.status]);

  return [
    state,
    {
      text: useCallback(
        (text) => {
          setState((curr) => ({ ...curr, text }));
        },
        [state.text]
      ),
      type: useCallback(
        (type) => {
          setState((curr) => ({ ...curr, type }));
        },
        [state.type]
      ),
      status: useCallback(
        (status) => {
          setState((curr) => ({ ...curr, status }));
        },
        [state.status]
      )
    }
  ];
};

export default useInstitutionFilterV2;
