import React from 'react';
import Link from 'next/link';
import { GetProps } from 'src/lib/react/types';
import { flow } from 'src/lib/function';
import * as models from 'src/models';
import * as store from 'src/store';
import * as localization from 'src/localization';
import * as cssClass from 'src/lib/cssClass';
import * as styles from './styles';
import useTheme from 'src/hooks/useTheme';
import useLocalized from 'src/hooks/useLocalized';
import useStoreSelector from 'src/hooks/useStoreSelector';
import Text from 'src/components/Text';
import Anchor from 'src/components/Anchor';
import Badge from 'src/components/Badge';
import Label from 'src/components/Label';
import Row from 'src/components/Row';

export type Props = Omit<GetProps<typeof Row>, 'children'> & {
  id: keyof store.state.institutionRecord.types.State;
};

const InstitutionListRow: React.FC<Props> = ({ id, ...rest }) => {
  const theme = useTheme();
  const institution = useStoreSelector(
    flow(
      store.state.selectors.makeSelectInstitutionRecord(),
      store.state.institutionRecord.selectors.makeSelectByID(id)
    )
  );
  const localized = useLocalized(
    localization.models.institution.create(institution || models.institution.constants.unit)
  );

  return institution === null ? (
    <></>
  ) : (
    <Link href={`/institutions/${id}`} passHref>
      <Anchor variant="tertiary">
        <Row responsive={{ at: 'sm' }} {...rest}>
          <Row.Column className={styles.title(theme)} size={11}>
            <Text className={cssClass.mr('10px')} variant="body">
              {localized.name}
            </Text>
            <Badge className={styles.badge(theme)} variant="caption">
              {localized.type}
            </Badge>
          </Row.Column>
          <Row.Column className={styles.labels(theme)} size={1}>
            <Label
              className={styles.label(theme)}
              variant="body"
              color={institution.status === 'active' ? 'success' : 'disabled'}
            >
              {localized.status}
            </Label>
          </Row.Column>
        </Row>
      </Anchor>
    </Link>
  );
};

export default InstitutionListRow;
