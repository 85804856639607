import * as constants from './constants';
import * as types from './types';

export { constants, types }

export const fromWindow = (w: Window): types.Rect => {
  return {
    width: w.innerWidth,
    height: w.innerHeight
  }
}

export const equal = (x: types.Rect, y: types.Rect): boolean => {
  return x.width === y.width && x.height === y.height
}

