import React from 'react';
import { cx } from '@emotion/css';
import { GetProps } from 'src/lib/react/types';
import * as styles from './styles';
import useTheme from 'src/hooks/useTheme';
import Text from 'src/components/Text';

export type Props = GetProps<typeof Text> & {
  type?: 'neutral';
};

const Badge: React.FC<Props> = ({ type = 'neutral', className, ...rest }) => {
  const theme = useTheme();
  return <Text className={cx(styles.main(), { neutral: styles.neutral(theme) }[type])} {...rest} />;
};

export default Badge;
