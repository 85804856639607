import { css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (_: Theme) => css`
  display: inline-flex;
  align-items: center;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
`;

export const neutral = (theme: Theme) => css`
  color: ${theme.color.white};
  background: ${theme.color.grey80};
`;

export const success = (theme: Theme) => css`
  color: ${theme.color.white};
  background: ${theme.color.green80};
`;

export const disabled = (theme: Theme) => css`
  color: ${theme.color.white};
  background: ${theme.color.grey60};
`;
