import { useRouter } from 'next/router';
import { pipe } from 'src/lib/function';
import * as models from 'src/models';
import useWindow from 'src/hooks/useWindow';
import useIsomorphicLayoutEffect from 'src/hooks/useIsomorphicLayoutEffect';

const useWindowScrollRecorder = () => {
  const window = useWindow();
  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    return () => {
      if (window) {
        window.localStorage.setItem(router.pathname, pipe(models.scroll.fromWindow(window), models.scroll.toString));
      }
    };
  }, [router.pathname, window]);
};

export default useWindowScrollRecorder;
