import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { flow, pipe } from 'src/lib/function';
import * as maybe from 'src/lib/maybe';
import * as result from 'src/lib/result';
import * as models from 'src/models';
import useWindow from 'src/hooks/useWindow';
import useRehydrationStatus from 'src/hooks/useRehydrationStatus';

// stores by location
const useWindowScrollRestorer = () => {
  const window = useWindow();
  const router = useRouter();
  const status = useRehydrationStatus();

  useEffect(() => {
    const scroll = pipe(
      maybe.fromNullable(window),
      maybe.bind((w) => pipe(w.localStorage.getItem(router.pathname), maybe.fromNullable)),
      maybe.bind((s) => pipe(models.scroll.fromString(s), flow(result.toNullable, maybe.fromNullable))),
      maybe.toNullable
    );
    if (scroll && window && status === 'complete') {
      window.localStorage.removeItem(router.pathname);
      window.scroll(scroll.x, scroll.y);
    }
  }, [router.pathname, status, window]);
};

export default useWindowScrollRestorer;
