import React, { HTMLAttributes, Ref } from 'react';
import { cx } from '@emotion/css';
import { Typography } from 'src/lib/theme/types';
import useTheme from 'src/hooks/useTheme';
import * as styles from './styles';

export type Props = HTMLAttributes<HTMLLabelElement> & {
  variant?: Typography;
};

const FormLabel: React.FC<Props & { innerRef: Ref<HTMLLabelElement> }> = ({
  variant = 'body',
  className,
  innerRef,
  ...rest
}) => {
  const theme = useTheme();
  return <label className={cx(theme.typography[variant], styles.main(), className)} ref={innerRef} {...rest} />;
};

export default React.forwardRef<HTMLLabelElement, Props>((props, ref) => {
  return <FormLabel innerRef={ref} {...props} />;
});
