import React from 'react';
import { GetProps } from 'src/lib/react/types';
import * as models from 'src/models';
import ListGroupData from 'src/components/ListGroupData';
import InstitutionListRow from 'src/components/InstitutionListRow';

export type Props = Omit<GetProps<typeof ListGroupData>, 'children' | 'data'> & {
  ids: models.institution.types.Institution['entityKey'][];
};

const InstitutionList: React.FC<Props> = ({ ids, ...rest }) => {
  return (
    <ListGroupData data={ids} {...rest}>
      {(id) => <InstitutionListRow id={id} />}
    </ListGroupData>
  );
};

export default InstitutionList;
