import { cx, css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';
import * as cssClass from 'src/lib/cssClass';

export const labels = (theme: Theme) =>
  cx(
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      & > * {
        margin: 5px 5px 5px 0;
      }
    `,
    cssClass.maxScreen(theme.breakpoint.sm.to)(cssClass.justifyContent('flex-start')),
    cssClass.minScreen(theme.breakpoint.md.from)(cssClass.justifyContent('flex-end'))
  );

export const label = (_: Theme) => css`
  height: 22px;
`;

export const title = (theme: Theme) =>
  cx(
    css`
      height: fit-content;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      & > * {
        margin: 5px 5px 5px 0;
      }
    `,
    cssClass.maxScreen(theme.breakpoint.sm.to)(cssClass.justifyContent('flex-start')),
    cssClass.minScreen(theme.breakpoint.md.from)(cssClass.justifyContent('flex-start'))
  );

export const badge = (_: Theme) => css`
  height: 24px;
`;
