import React, { useEffect } from 'react';
import { cx } from '@emotion/css';
import * as cssClass from 'src/lib/cssClass';
import * as models from 'src/models';
import * as localization from 'src/localization';
import usePrevious from 'src/hooks/usePrevious';
import useLocalized from 'src/hooks/useLocalized';
import useInstitutionFilterV2 from 'src/hooks/useInstitutionFilterV2';
import Pill from 'src/components/Pill';
import NavList from 'src/components/NavList';
import InputText from 'src/components/InputText';
import FormLabel from 'src/components/FormLabel';
import FormGroup from 'src/components/FormGroup';

export type Props = {
  types: models.institutionFilterV2.types.InstitutionFilterV2['type'][];
  statuses: models.institutionFilterV2.types.InstitutionFilterV2['status'][];
  state: models.institutionFilterV2.types.InstitutionFilterV2;
  onChange: (state: models.institutionFilterV2.types.InstitutionFilterV2) => void;
};

const InstitutionFilterV2: React.FC<Props> = ({
  types,
  statuses,
  state,
  onChange = () => undefined
}) => {
  const [curr, setters] = useInstitutionFilterV2(state);
  const prev = usePrevious(curr);
  const localized = useLocalized((env) => ({
    component: localization.components.InstitutionFilterV2.localized(env),
    filter: localization.models.institutionFilterV2.localized(env)
  }));

  useEffect(() => {
    onChange(curr);
  }, []);

  useEffect(() => {
    if (prev && !models.institutionFilterV2.equal(prev, curr)) {
      onChange(curr);
    }
  }, [curr]);

  return (
    <>
      <FormGroup className={cx(cssClass.mt('20px'), cssClass.mb('1.5rem'))}>
        <FormLabel variant="subhead">{localized.component.text.label}</FormLabel>
        <InputText value={curr.text} onChange={setters.text} placeholder={localized.component.text.placeholder} />
      </FormGroup>
      <FormGroup className={cssClass.mb('1.25rem')}>
        <FormLabel variant="subhead">{localized.component.type.label}</FormLabel>
        <NavList>
          {types.map((type) => (
            <NavList.Item key={type}>
              <Pill status={type === curr.type ? 'active' : 'inactive'} value={type} onClick={setters.type}>
                {localized.filter.type[type]}
              </Pill>
            </NavList.Item>
          ))}
        </NavList>
      </FormGroup>
      <FormGroup className={cssClass.mb('1.25rem')}>
        <FormLabel variant="subhead">{localized.component.status.label}</FormLabel>
        <NavList>
          {statuses.map((status) => (
            <NavList.Item key={status}>
              <Pill status={status === curr.status ? 'active' : 'inactive'} value={status} onClick={setters.status}>
                {localized.filter.status[status]}
              </Pill>
            </NavList.Item>
          ))}
        </NavList>
      </FormGroup>
    </>
  );
};

export default InstitutionFilterV2;
