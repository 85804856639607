import { useCallback, useEffect, useState } from 'react';
import * as models from 'src/models';

type State = models.institutionFilter.types.InstitutionFilter;

type Payload = [
  State,
  {
    [K in keyof State]: (value: State[K]) => void;
  }
];

const useInstitutionFilter = (initialState: State): Payload => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!models.institutionFilter.equal(initialState, state)) {
      setState(initialState);
    }
  }, [initialState.text, initialState.tag]);

  return [
    state,
    {
      text: useCallback(
        (text) => {
          setState((curr) => ({ ...curr, text }));
        },
        [state.text]
      ),
      tag: useCallback(
        (tag) => {
          setState((curr) => ({ ...curr, tag }));
        },
        [state.tag]
      )
    }
  ];
};

export default useInstitutionFilter;
