import React from 'react';
import Head from 'next/head';
import { cx } from '@emotion/css';
import { flow } from 'src/lib/function';
import * as store from 'src/store';
import * as localization from 'src/localization';
import * as cssClass from 'src/lib/cssClass';
import useBuildDate from 'src/hooks/useBuildDate';
import useClientConfig from 'src/hooks/useClientConfig';
import useLocalized from 'src/hooks/useLocalized';
import useStoreSelector from 'src/hooks/useStoreSelector';
import useWindowScrollRecorder from 'src/hooks/useWindowScrollRecorder';
import useWindowScrollRestorer from 'src/hooks/useWindowScrollRestorer';
import Divider from 'src/components/Divider';
import Row from 'src/components/Row';
import Heading from 'src/components/Heading';
import Paragraph from 'src/components/Paragraph';
import HelpCreditCard from 'src/components/HelpCreditCard';
import HelpInstitution from 'src/components/HelpInstitution';
import InstitutionsSearch from 'src/components/InstitutionsSearch';
import InstitutionsSearchV2 from 'src/components/InstitutionsSearchV2';

const InstitutionsOverview: React.FC = () => {
  const clientConfig = useClientConfig();
  const date = useBuildDate();
  const ids = useStoreSelector(
    flow(
      store.state.selectors.makeSelectInstitutionRecord(),
      store.state.institutionRecord.selectors.makeSelectSupportedIDs()
    )
  );
  const localized = useLocalized(
    localization.views.InstitutionsOverview.create({
      date,
      total: ids.length
    })
  );

  useWindowScrollRestorer();
  useWindowScrollRecorder();

  return (
    <>
      <Head>
        <title>{localized.title}</title>
      </Head>
      <Row responsive={{ at: 'md', reverse: true }}>
        <Row.Column size={2}>
          <Heading level={1} className={cx(cssClass.mt('20px'), cssClass.mb('10px'))}>
            {localized.title}
          </Heading>
          <Divider />
          <div>
            <Paragraph variant="body">{localized.details.explainer}</Paragraph>
            <br />
            <Paragraph variant="body">{localized.details.note}</Paragraph>
          </div>
          {clientConfig.features.institutionFilterV2 === 'enabled' ? <InstitutionsSearchV2 /> : <InstitutionsSearch />}
        </Row.Column>
        <Row.Column size={1}>
          <HelpInstitution />
          <Divider visibility="hidden" />
          <HelpCreditCard />
          <Divider visibility="hidden" />
        </Row.Column>
      </Row>
    </>
  );
};

export default InstitutionsOverview;
