import React, { forwardRef, Ref, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

export type Props = HTMLAttributes<HTMLLIElement> & {
  innerRef: Ref<HTMLLIElement>;
}

const Item: React.FC<Props> = ({ className, innerRef, ...rest }) => (
  <li className={cx(className, styles.main)} ref={innerRef} {...rest} />
)

export default forwardRef<HTMLLIElement, Omit<Props, 'innerRef'>>((props, ref) => {
  return <Item {...props} innerRef={ref} />;
});
