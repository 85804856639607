import { cx, css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (theme: Theme) =>
  cx(
    theme.typography.input,
    css`
      height: 48px;
      width: 100%;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      transition: border-bottom-color 0.3s;

      &::placeholder {
        color: ${theme.color.grey60};
        opacity: 1; /* Firefox */
      }
    `
  );

export const input = (theme: Theme) => css`
  border-color: ${theme.color.black};
`;

export const focus = (theme: Theme) => css`
  border-color: ${theme.color.black};
`;
