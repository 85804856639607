import { useEffect } from 'react';
import useWindow from 'src/hooks/useWindow';

const useWindowEvent = (event: string, handler: EventListenerOrEventListenerObject) => {
  const window = useWindow();

  useEffect(() => {
    if (window) {
      window.addEventListener(event, handler);
    }
    return () => {
      if (window) {
        window.removeEventListener(event, handler);
      }
    };
  }, [handler, window]);
};

export default useWindowEvent;
