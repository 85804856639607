import React, { useMemo } from 'react';
import { animated, useTransition } from 'react-spring';
import { GetProps } from 'src/lib/react/types';
import * as cssClass from 'src/lib/cssClass';
import * as styles from './styles';
import * as models from 'src/models';
import * as localization from 'src/localization';
import useLocalized from 'src/hooks/useLocalized';
import Paragraph from 'src/components/Paragraph';
import InstitutionList from 'src/components/InstitutionList';

export type Props = Omit<GetProps<typeof InstitutionList>, 'style' | 'ids'> & {
  ids: models.institution.types.Institution['entityKey'][];
};

function useFadeTransition<A>(data: A) {
  return useTransition(data, {
    key: JSON.stringify(data),
    exitBeforeEnter: true,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 200 }
  });
}

const InstitutionsSearchResult: React.FC<Props> = ({ ids, ...rest }) => {
  const transition = useFadeTransition(useMemo(() => ({ ids }), [ids]));
  const localized = useLocalized(localization.components.InstitutionsSearchResult.create({ total: ids.length }));

  return (
    <>
      <Paragraph className={cssClass.mb('10px')} variant="body">
        {localized.match}
      </Paragraph>
      {transition((style, item) => (
        <animated.div className={styles.animated} style={style}>
          <InstitutionList ids={item.ids} {...rest} />
        </animated.div>
      ))}
    </>
  );
};

export default InstitutionsSearchResult;
