import React, { useCallback, useMemo } from 'react';
import { debounce } from 'throttle-debounce';
import { flow } from 'src/lib/function';
import * as store from 'src/store';
import * as models from 'src/models';
import usePersistingState from 'src/hooks/usePersistingState';
import useStoreSelector from 'src/hooks/useStoreSelector';
import ClientSideRender from 'src/components/ClientSideRender';
import InstitutionFilterV2 from 'src/components/InstitutionFilterV2';
import InstitutionsSearchResult from 'src/components/InstitutionsSearchResult';

const InstitutionsSearchV2: React.FC = () => {
  const [filter, setFilter] = usePersistingState('institutionFilterV2', models.institutionFilterV2.serialize);
  const ids = useStoreSelector(
    useMemo(
      () =>
        flow(
          store.state.selectors.makeSelectInstitutionRecord(),
          store.state.institutionRecord.selectors.makeSelectIDsByFilterV2(filter)
        ),
      [filter.text, filter.type, filter.status]
    )
  );
  const onChange = useCallback(debounce(1000, false, setFilter), []);

  return (
    <ClientSideRender>
      <InstitutionFilterV2
        types={models.institutionFilterV2.constants.types}
        statuses={models.institutionFilterV2.constants.statuses}
        state={filter}
        onChange={onChange}
      />
      <InstitutionsSearchResult ids={ids} />
    </ClientSideRender>
  );
};

export default InstitutionsSearchV2;
