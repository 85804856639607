import React, { useEffect } from 'react';
import { cx } from '@emotion/css';
import * as cssClass from 'src/lib/cssClass';
import * as models from 'src/models';
import * as localization from 'src/localization';
import usePrevious from 'src/hooks/usePrevious';
import useLocalized from 'src/hooks/useLocalized';
import useInstitutionFilter from 'src/hooks/useInstitutionFilter';
import Pill from 'src/components/Pill';
import InputText from 'src/components/InputText';
import NavList from 'src/components/NavList';

export type Props = {
  tags: models.institutionTag.types.InstitutionTag[];
  state: models.institutionFilter.types.InstitutionFilter;
  onChange: (state: models.institutionFilter.types.InstitutionFilter) => void;
};

const InstitutionFilter: React.FC<Props> = ({ tags, state, onChange = () => undefined }) => {
  const [curr, setters] = useInstitutionFilter(state);
  const prev = usePrevious(curr);
  const localized = useLocalized((env) => ({
    component: localization.components.InstitutionFilter.localized(env),
    tags: localization.models.institutionTag.localized(env)
  }));

  useEffect(() => {
    onChange(curr);
  }, []);

  useEffect(() => {
    if (prev && !models.institutionFilter.equal(prev, curr)) {
      onChange(curr);
    }
  }, [curr]);

  return (
    <>
      <div className={cx(cssClass.mt('20px'), cssClass.mb('25px'))}>
        <InputText value={curr.text} onChange={setters.text} placeholder={localized.component.input} />
      </div>
      <NavList className={cx(cssClass.mb('10px'))}>
        {tags.map((tag) => (
          <NavList.Item key={tag}>
            <Pill status={tag === curr.tag ? 'active' : 'inactive'} value={tag} onClick={setters.tag}>
              {localized.tags[tag]}
            </Pill>
          </NavList.Item>
        ))}
      </NavList>
    </>
  );
};

export default InstitutionFilter;
