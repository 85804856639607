import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { NextPage, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { cx } from '@emotion/css';
import * as store from 'src/store';
import * as models from 'src/models';
import * as configs from 'src/configs';
import * as services from 'src/services';
import * as cssClass from 'src/lib/cssClass';
import * as styles from 'src/styles';
import { LocaleConfigProvider } from 'src/contexts/LocaleConfig';
import { BuildTimestampProvider } from 'src/contexts/BuildTimestamp';
import useClientConfig from 'src/hooks/useClientConfig';
import useTheme from 'src/hooks/useTheme';
import AppNavbar from 'src/components/AppNavbar';
import AppHeader from 'src/components/AppHeader';
import AppFooter from 'src/components/AppFooter';
import Divider from 'src/components/Divider';
import Body from 'src/components/Body';
import Unit from 'src/components/Unit';
import InstitutionsOverview from 'src/views/InstitutionsOverview';

export type Props = {
  timestamp: number;
  localeConfig: configs.locale.types.Config;
  institutionRecord: models.visibleInstitutionRecord.types.VisibleInstitutionRecord;
};

const Root: NextPage<Props> = ({ timestamp, institutionRecord, localeConfig }) => {
  const clientConfig = useClientConfig();
  const theme = useTheme();
  const initialState = useMemo(
    () =>
      [store.state.institutionRecord.actions.set(institutionRecord)].reduce(
        store.state.reducers.root,
        store.state.reducers.initialState
      ),
    [institutionRecord]
  );

  return (
    <BuildTimestampProvider value={timestamp}>
      <LocaleConfigProvider value={localeConfig}>
        <Provider store={store.create(initialState)(clientConfig.store)}>
          <AppNavbar className={cx(cssClass.mb('20px'))}>
            <AppHeader className={styles.views.main(theme)} />
          </AppNavbar>
          <Body className={styles.views.main(theme)}>
            <InstitutionsOverview />
            <Unit className={cx(cssClass.mt('50px'))} />
            <Divider />
            <AppFooter />
            <Unit className={cx(cssClass.mb('50px'))} />
          </Body>
        </Provider>
      </LocaleConfigProvider>
    </BuildTimestampProvider>
  );
};

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  try {
    const serverConfig = configs.server.fromServerEnv(
      await configs.serverEnv.fromEncrypted(services.decrypt.kms)(configs.serverEnv.fromEnv())
    );
    const localeConfig = configs.locale.fromStaticRoute({
      context,
      namespace: ['common', 'institutions']
    });
    const institutionRecord = await serverConfig.repositories.linkInstitutionRecord.getByLocale(localeConfig.locale);
    const translations = await serverSideTranslations(localeConfig.locale, localeConfig.namespace);

    return {
      props: { ...translations, localeConfig, institutionRecord, timestamp: new Date().getTime() }
    };
  } catch (error) {
    // TODO: error component?
    throw error;
  }
};

export default Root;
