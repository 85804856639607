import { css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = () => css`
  display: inline-flex;
  align-items: center;
  min-width: 10px;
  padding: 8px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 100px;
`;

export const neutral = (theme: Theme) => css`
  color: ${theme.color.black};
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.grey90};
`;
