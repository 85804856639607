import { useRef, RefObject } from 'react';
import { useVirtual } from 'react-virtual';
import useWindow from 'src/hooks/useWindow';
import useWindowRect from 'src/hooks/useWindowRect';

export type Options = {
  size: number;
  parentRef: RefObject<HTMLElement>;
  overscan?: number;
};

const useVirtualWindow = ({ size, parentRef, overscan }: Options): ReturnType<typeof useVirtual> => {
  const windowRef = useRef(useWindow());

  return useVirtual({
    size,
    parentRef,
    overscan,
    onScrollElement: windowRef as any, // works with window
    scrollToFn: (offset) => {
      if (windowRef.current) {
        windowRef.current.scrollY = offset;
      }
    },
    scrollOffsetFn() {
      if (parentRef.current) {
        return parentRef.current.getBoundingClientRect().top * -1;
      } else {
        return 0;
      }
    },
    useObserver: () => useWindowRect()
  });
};

export default useVirtualWindow;
