import React, { useCallback, useMemo } from 'react';
import { debounce } from 'throttle-debounce';
import * as store from 'src/store';
import * as models from 'src/models';
import { flow } from 'src/lib/function';
import usePersistingState from 'src/hooks/usePersistingState';
import useStoreSelector from 'src/hooks/useStoreSelector';
import ClientSideRender from 'src/components/ClientSideRender';
import InstitutionFilter from 'src/components/InstitutionFilter';
import InstitutionsSearchResult from 'src/components/InstitutionsSearchResult';

const InstitutionsSearch: React.FC = () => {
  const [filter, setFilter] = usePersistingState('institutionFilter', models.institutionFilter.serialize);
  const ids = useStoreSelector(
    useMemo(
      () =>
        flow(
          store.state.selectors.makeSelectInstitutionRecord(),
          store.state.institutionRecord.selectors.makeSelectIDsByFilter(filter)
        ),
      [filter.text, filter.tag]
    )
  );
  const onChange = useCallback(debounce(1000, setFilter), []);

  return (
    <ClientSideRender>
      <InstitutionFilter tags={models.institutionTag.constants.tags} state={filter} onChange={onChange} />
      <InstitutionsSearchResult ids={ids} />
    </ClientSideRender>
  );
};

export default InstitutionsSearch;
