import React, { useCallback, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import useTheme from 'src/hooks/useTheme';
import * as styles from './styles';

export type Props<A> = Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  status?: 'active' | 'inactive';
  value: A;
  onClick: (value: A) => void;
};

function Pill<A>({ className, value, onClick: onClick_, status = 'inactive', ...rest }: Props<A>) {
  const theme = useTheme();
  const onClick = useCallback(() => onClick_(value), [value, onClick_]);
  return (
    <button
      className={cx(
        className,
        styles.main(theme),
        { active: styles.active(theme), inactive: styles.inactive(theme) }[status]
      )}
      onClick={onClick}
      {...rest}
    />
  );
}

export default Pill;
